import React from "react";
import { navigate } from "gatsby";

import Form from "./Form";

const SingleSearch = () => {
  const handleSubmit = React.useCallback((state) => {
    navigate("/comparer/", { replace: true, state });
  }, []);

  return <Form callback={handleSubmit} />;
};

export default SingleSearch;
