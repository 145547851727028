import React from "react";
import styled from "styled-components";

import SEO from "../components/Seo";
import Header from "../components/Header";
import SingleSearch from "../components/compare/search/SingleSearch";
import Offers from "../components/compare/offers/Offers";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #ffffff;
`;
const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  position: relative;
  margin: 0 auto;
  padding: 1rem;
`;
const Title = styled.h1`
  font-size: 1.6rem;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  @media (max-width: 576px) {
    font-size: 1.3rem;
  }
`;

export default () => {
  return (
    <>
      <SEO
        title={`Location utilitaire ${process.env.REACT_APP_VILLE}`}
        lang="fr"
        description={`Louer un utilitaire à ${process.env.REACT_APP_VILLE} et sa région`}
      />
      <Header ville={process.env.REACT_APP_VILLE} />
      <SingleSearch />
      <Wrapper>
        <Container>
          <Title>LOCATION UTILITAIRE {process.env.REACT_APP_VILLE}</Title>
          <p>
            Louez un utilitaire sur Marseille au meilleur rapport qualité prix
            avec location-utilitaire-marseille.com. Notre site sera votre
            meilleur allié pour louer un utilitaire sur Marseille et sa région.
          </p>
          <p>
            Nous comparons les prix des loueurs présents sur Marseille afin de
            vous faciliter le travail{"\u00A0"}! Vous accéderez ainsi à un
            maximum d’offres de location utilitaires à Marseille et sa région.
            Lancez une recherche dans notre moteur, consultez les résultats,
            localisez les agences et véhicules disponibles sur la carte
            interactive à droite des résultats et faites votre choix. Vous serez
            ensuite redirigé vers le site du loueur afin de réserver votre
            location utilitaire par vous même. Vous gardez ainsi le contrôle sur
            votre réservation puisque le paiement se fera directement avec le
            loueur d’utilitaire que vous aurez choisi. La location utilitaire
            sur Marseille et sa région est donc rendue facile grâce à notre
            moteur de recherche unique{"\u00A0"}!
          </p>
        </Container>
      </Wrapper>
      <Offers />
    </>
  );
};
