import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import _ from "lodash";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
`;
const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  position: relative;
  margin: 0 auto;
  padding: 1rem;
`;
const CompanyWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;
const CompanyTitle = styled.h2`
  margin: 1.5rem 0 0.5rem 0;
  font-size: 1.4rem;
  font-weight: bold;
  @media (max-width: 576px) {
    font-size: 1.2rem;
  }
`;
const Agencies = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  column-gap: 2rem;
  row-gap: 2rem;
  @media (max-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
`;
const AgencyWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 0 0 30px 30px;
`;
const AgencyTitle = styled.div`
  background-color: #515151;
  color: #ffffff;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1rem;
  padding: 8px 1rem;
  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
`;
const AgencyContent = styled.div`
  padding: 1rem;
`;
const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: stretch;
  }
`;
const Address = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
const OpeningTime = styled.div`
  flex: 7;
`;
const OpeningTimeList = styled.div`
  font-size: 0.6rem;
  td {
    border: 1px solid #eee;
    position: relative;
    padding: 3px;
    width: 14.285%;
    @media (max-width: 360px) {
      padding: 1px;
      font-size: 0.5rem;
    }
  }
  th {
    border: 1px solid #eee;
    background-color: #eee;
    padding: 3px;
    width: 14.285%;
    @media (max-width: 360px) {
      padding: 1px;
      font-size: 0.5rem;
    }
  }
`;
const LogoImg = styled.img`
  width: 120px;
  margin: 0 auto 1rem auto;
  flex: none;
  @media (max-width: 420px) {
    width: 100px;
    margin-right: 10px;
  }
`;
const AddressDetails = styled.div`
  flex: 1;
  font-size: 0.9rem;
`;
const AgencySubtitle = styled.h5`
  margin: 0 0 0.5rem 0;
  font-size: 0.7rem;
  font-weight: bold;
`;
const Items = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  column-gap: 1rem;
  row-gap: 1.2rem;
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.8rem;
  padding: 8px;
  border: 4px solid #f2f2f2;
  border-radius: 16px;
`;
const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 361px) and (max-width: 1024px) {
    flex-direction: row;
  }
`;
const ItemLeft = styled.div`
  @media (max-width: 1024px) {
    flex: 3;
    margin-right: 1rem;
  }
`;
const ItemRight = styled.div`
  @media (max-width: 1024px) {
    flex: 4;
    font-size: 0.7rem;
  }
`;
const ItemPhoto = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 80px;
  }
`;
const ItemTitle = styled.h3`
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
`;
const Price = styled.div`
  color: ${primaryColor};
  font-size: 1.5rem;
  text-align: center;
  margin: 0.6rem 0;
`;
const Button = styled.div`
  display: block;
  width: 100%;
  color: ${primaryColor};
  border: 1px solid ${primaryColor};
  text-align: center;
  font-size: 0.8rem;
  padding: 4px;
  border-radius: 6px;
  &:hover {
    background-color: ${primaryColor};
    color: #ffffff;
  }
`;

const Photo = ({ data }) => {
  const alt = `Location utilitaire ${data.vehicule} ${data.agence}`;

  if (data.featuredImg !== null && data.featuredImg.childImageSharp.fixed.src)
    return <Img fixed={data.featuredImg.childImageSharp.fixed} alt={alt} />;

  return (
    <LazyLoad height="100%" offset={100} once>
      <img alt={alt} src={data.img} style={{ height: "100%" }} />
    </LazyLoad>
  );
};

const Logo = ({ name, logo }) => {
  return (
    <LazyLoad height="100%" offset={100} once>
      <LogoImg alt={`Location utilitaire ${name}`} src={logo} />
    </LazyLoad>
  );
};

const Offer = ({ data }) => {
  return (
    <Item>
      <ItemTitle>{data.vehicule}</ItemTitle>
      <ItemContent>
        <ItemLeft>
          <ItemPhoto>
            <Photo data={data} />
          </ItemPhoto>
        </ItemLeft>
        <ItemRight>
          Le {data.date_depart} de 9h à 18h :<br />
          <Price>
            {new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(data.prix)}
            *
          </Price>
          <Button
            target="_blank"
            rel="noopener noreferrer"
            href={data.cloacking}
          >
            Réserver maintenant
          </Button>
        </ItemRight>
      </ItemContent>
    </Item>
  );
};

/**
 * Offers by agency
 * @param {Array} props.data
 */
const Agency = ({ data, name, logo }) => {
  if (data.offres.length === 0) return null;

  const title = `Location Utilitaire ${
    data.info.agence
  } ${data.info.name.toUpperCase()}`;

  // Order offers by price and take 4 cheapest
  let offers = _.orderBy(data.offres, [(item) => item.prix], ["asc"]);
  offers = offers.slice(0, 4);

  return (
    <AgencyWrapper>
      <AgencyTitle>{title}</AgencyTitle>
      <AgencyContent>
        <Infos>
          <Address>
            <Logo name={name} logo={logo} />
            <AddressDetails>
              <AgencySubtitle>Adresse :</AgencySubtitle>
              {data.info.adresse}
              <br />
              {data.info.cp}
            </AddressDetails>
          </Address>
          <OpeningTime>
            <AgencySubtitle>Horaires :</AgencySubtitle>
            <OpeningTimeList
              dangerouslySetInnerHTML={{
                __html: data.info.hours,
              }}
            ></OpeningTimeList>
          </OpeningTime>
        </Infos>
        <Items>
          {offers.map((item) => (
            <Offer
              key={`${data.info.agence}_${data.info.idag}_${item.idoffre}`}
              data={item}
            />
          ))}
        </Items>
      </AgencyContent>
    </AgencyWrapper>
  );
};

/**
 * Offers by company
 * @param {Array} props.data
 */
const Company = ({ data }) => {
  if (data.data.length === 0) return null;

  return (
    <CompanyWrapper>
      <CompanyTitle>
        Location Utilitaire {process.env.REACT_APP_VILLE} {data.loueur} :
      </CompanyTitle>
      <Agencies>
        {data.data.map((agence) => (
          <Agency
            key={`${data.loueur} ${agence.info.idag}`}
            data={agence}
            name={data.loueur}
            logo={data.logoUrl}
          />
        ))}
      </Agencies>
    </CompanyWrapper>
  );
};

/**
 * All offers
 * @param {Array} props.data
 */
const Offers = ({ data }) => {
  if (data.allOffersJson.nodes.length === 0) return null;
  return (
    <Wrapper>
      <Container>
        {data.allOffersJson.nodes.map((loueurs) => (
          <Company key={`comp${loueurs.loueur}`} data={loueurs} />
        ))}
      </Container>
    </Wrapper>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query OffersQuery {
        allOffersJson(
          filter: {
            loueur: {
              in: [
                "AVIS"
                "HERTZ"
                "EUROPCAR"
                "FRANCE-CARS"
                "ADA"
                "SIXT"
                "CARGO"
                "FLYCAR"
                "ENTERPRISE"
              ]
            }
          }
        ) {
          nodes {
            loueur
            logoUrl
            data {
              info {
                idag
                agence
                name
                hours
                adresse
                cp
              }
              offres {
                idoffre
                agence
                vehicule
                prix
                img
                cloacking
                hm_depart
                hm_retour
                date_depart(formatString: "DD/MM/YYYY")
                featuredImg {
                  childImageSharp {
                    fixed(height: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Offers data={data} />}
  />
);
